import * as UPSELL_FROM from 'modules/Analytics/constants/upsellFrom';
import ArtistAlbums from 'views/Artist/Albums/ArtistAlbums';
import ArtistBio from './ArtistBio';
import ArtistHead from 'views/Artist/ArtistHead';
import ArtistHero from 'views/Artist/ArtistHero';
import ArtistSimilarSection from 'views/Artist/ArtistSimilarSection';
import checkAutoplay from 'web-player/autoplay';
import LatestRelease from './LatestRelease';
import ListenInApp from 'components/ListenInApp';
import PageBody, { ViewName } from 'views/PageBody';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PopularOn from './PopularOn';
import Section from 'components/Section';
import ShouldShow from 'components/ShouldShow';
import TopSongs from './TopSongs';
import UPSELL from 'constants/upsellTracking';
import { fit } from 'utils/mediaServerImageMaker/opsString';
import { getArtistAlbumsUrl } from 'views/Artist/helpers';
import { GrowlIcons } from 'components/Growls/constants';
import { LatestNews, NewsChild } from 'components/LatestNews';
import { PureComponent } from 'react';
import {
  SaveDeleteComponent,
  SaveDeleteView,
} from 'modules/Analytics/helpers/saveDelete';
import { showNotifyGrowl } from 'state/UI/actions';
import { STATION_TYPE } from 'constants/stationTypes';
import { WIDGET_DIMENSIONS } from 'constants/widgetDimensions';

class ArtistProfile extends PureComponent {
  componentDidMount() {
    const { artistId, customRadioEnabled, stationLoaded } = this.props;

    if (stationLoaded) {
      checkAutoplay({
        customRadioEnabled,
        seedId: artistId,
        seedType: STATION_TYPE.ARTIST,
      });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ stationLoaded }) {
    const { artistId, customRadioEnabled } = this.props;

    if (stationLoaded !== this.props.stationLoaded) {
      checkAutoplay({
        customRadioEnabled,
        seedId: artistId,
        seedType: STATION_TYPE.ARTIST,
      });
    }
  }

  onAddAlbumToMyMusic = albumId => {
    const {
      allAccessPreview,
      isAnonymous,
      openSignup,
      saveAlbum,
      saveAlbumOverflow,
      translate,
    } = this.props;

    if (allAccessPreview && isAnonymous) {
      openSignup('all_access_Preview');
    } else if (saveAlbumOverflow) {
      saveAlbum(albumId);

      showNotifyGrowl({
        icon: GrowlIcons.CheckCircle,
        title: translate('Album saved to Your Library'),
      });
    } else {
      this.addAlbumToMyMusicUpsell();
    }
  };

  onAddAlbumToPlaylist = albumId => {
    const {
      canAddTrackToPlaylist,
      allAccessPreview,
      isAnonymous,
      openAddToPlaylist,
      openSignup,
      requestAlbum,
    } = this.props;
    requestAlbum({ albumId }).then(album => {
      if (!album.tracks) return;
      const trackIds = album.tracks.map(track => track.id);
      if (allAccessPreview && isAnonymous) {
        openSignup('all_access_Preview');
      } else if (canAddTrackToPlaylist) {
        openAddToPlaylist({
          component: SaveDeleteComponent.ListAlbumsOverflow,
          trackIds,
          view: SaveDeleteView.ArtistProfile,
        });
      } else {
        this.addAlbumToPlaylistUpsell();
      }
    });
  };

  onAddToPlaylist = ({ trackId }) => {
    const { allAccessPreview, isAnonymous, openAddToPlaylist, openSignup } =
      this.props;

    if (allAccessPreview && isAnonymous) {
      openSignup('all_access_Preview');
    } else if (isAnonymous) {
      openSignup('add_to_playlist');
    } else {
      openAddToPlaylist({
        component: SaveDeleteComponent.ListSongsOverflow,
        trackIds: [trackId],
        type: STATION_TYPE.TRACK,
        view: SaveDeleteView.ArtistProfile,
      });
    }
  };

  addAlbumToPlaylistUpsell = () => {
    const { openUpsellModal, translate } = this.props;

    openUpsellModal({
      upsellFrom: UPSELL.ARTIST_ALBUMS_ADD_ALBUM_TO_PLAYLIST,
      headerCopy: translate(
        'Create unlimited playlists. Try iHeart All Access.',
      ),
      analyticsUpsellFrom: UPSELL_FROM.ARTIST_PROFILE_ADD_ALBUM_TO_PLAYLIST,
    });
  };

  render() {
    const {
      artistId,
      artistName,
      translate,
      artistBio,
      url,
      albums,
      overflowEntitlements,
      showAlbumHeader,
      albumPlayback,
      customRadioEnabled,
      latestRelease,
      artistPopularOn,
      articles,
      contentLink,
    } = this.props;

    const albumsFiltered = latestRelease
      ? albums.filter(album => album.albumId !== latestRelease.albumId)
      : albums;

    const social = {
      dimensions: WIDGET_DIMENSIONS.ARTIST,
      seedId: artistId,
      seedType: STATION_TYPE.ARTIST,
      supportsConnect: false,
      url,
    };

    return artistId ? (
      <>
        <ArtistHead
          artistId={artistId}
          pagePath={url}
          seedType={STATION_TYPE.ARTIST}
          translate={translate}
        />
        <ArtistHero />
        <ListenInApp seedId={artistId} seedType="artist" />
        <PageBody
          dataTest={ViewName.ArtistProfile}
          hasHero
          social={social}
          title={artistName}
        >
          <LatestRelease />
          <TopSongs
            onAddToMyMusic={this.onAddToMyMusic}
            onAddToPlaylist={this.onAddToPlaylist}
          />
          <ShouldShow shouldShow={albumsFiltered.length > 0}>
            <Section
              dataTest="section-albums"
              header={translate('Albums')}
              url={getArtistAlbumsUrl(artistId, artistName)}
            >
              <ArtistAlbums
                albumPlayback={albumPlayback}
                albums={albumsFiltered}
                artistId={artistId}
                artistName={artistName}
                customRadioEnabled={customRadioEnabled}
                onAddAlbumToMyMusic={this.onAddAlbumToMyMusic}
                onAddAlbumToPlaylist={this.onAddAlbumToPlaylist}
                overflowEntitlements={overflowEntitlements}
                playedFrom={PLAYED_FROM.PROF_ARTIST_MAIN_ALBUMS}
                singleRow
                tilesInRow={3}
                translate={translate}
              />
            </Section>
          </ShouldShow>
          <ArtistSimilarSection
            canPlaylist={showAlbumHeader}
            header={translate('Similar Artists')}
            playedFrom={PLAYED_FROM.PROF_ARTIST_SIMILAR}
            singleRow
            stationId={artistId}
            tilesHaveFixedHeight={false}
            tilesInRow={3}
            url={`${url}similar/`}
          />
          <PopularOn
            artistId={artistId}
            artistPopularOn={artistPopularOn}
            canPlaylist={showAlbumHeader}
            singleRow
            tilesInRow={3}
          />
          <LatestNews heading={`${artistName} News`} sectionUrl={`${url}news/`}>
            {articles.map(article => (
              <NewsChild
                href={`${contentLink}${article.slug}/`}
                img={article['@img']}
                isSponsored={article.is_sponsored}
                key={article.title}
                ops={[fit(770, 385)]}
                title={article.title}
              />
            ))}
          </LatestNews>
          <ArtistBio bio={artistBio} />
        </PageBody>
      </>
    ) : null;
  }
}

export default ArtistProfile;
