import ArtCellDefault from './ArtCellDefault';
import PlayButtonNumberCell from './PlayButtonNumberCell';
import styled from '@emotion/styled';

type Props = {
  playable: boolean;
  playButtonNumber: boolean;
};

const Row = styled('tr')<Props>(({ playable, playButtonNumber, theme }) => ({
  alignItems: 'center',
  display: 'flex',
  marginBottom: '0.5rem',
  minHeight: playButtonNumber ? '3rem' : '6rem',

  ...(playButtonNumber && playable
    ? {
        '&:hover': {
          [`${PlayButtonNumberCell}`]: {
            visibility: 'hidden',
          },
          [`${ArtCellDefault}`]: {
            a: {
              color: theme.colors.gray.dark,
            },
            button: {
              display: 'block',
            },
          },
        },
      }
    : {}),
}));

export default Row;
