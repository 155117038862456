import ActionsCell from './primitives/ActionsCell';
import ArtCellDefault from './primitives/ArtCellDefault';
import ArtCellPodcast from './primitives/ArtCellPodcast';
import ArtistCell from './primitives/ArtistCell';
import CatalogImage from 'components/MediaServerImage/CatalogImage';
import colors from 'styles/colors';
import countryCodes from 'constants/countryCodes';
import DisabledTitle from './primitives/DisabledTitle';
import DurationPodcast from './primitives/DurationPodcast';
import DurationPodcastMobile from './primitives/DurationPodcastMobile';
import Ellipsis from './primitives/Ellipsis';
import ExplicitLyrics from 'components/ExplicitLyrics';
import Hover from './primitives/Hover';
import MarkAsPlayedToggle from 'components/MarkAsPlayedToggle';
import Mask from './primitives/Mask';
import NavLink from 'components/NavLink';
import PlayButton from 'components/Player/PlayButtonContainer';
import PlayButtonNumberCell from './primitives/PlayButtonNumberCell';
import PlayButtonWrapper from './primitives/PlayButtonWrapper';
import PodcastCircleProgressContainer from './primitives/PodcastCircleProgressContainer';
import PodcastMetaData from './primitives/PodcastMetadata';
import Row from './primitives/Row';
import RowDropdown from './primitives/RowDropdown';
import Separator from './primitives/Separator';
import ShareButton from 'components/Social/Share';
import ShouldShow from 'components/ShouldShow';
import ThumbButton from './primitives/ThumbButton';
import ThumbsContainer from './primitives/ThumbsContainer';
import ThumbsDown from 'styles/icons/ThumbsDown';
import ThumbsUp from 'styles/icons/ThumbsUp';
import TrackCellDefault from './primitives/TrackCellDefault';
import TrackCellPodcast from './primitives/TrackCellPodcast';
import TrackNumberCell from './primitives/TrackNumberCell';
import transport from 'api/transport';
import withCancel, { CancelablePromise } from 'utils/withCancel';
import { Circle } from 'rc-progress';
import { Component } from 'react';
import { fit } from 'utils/mediaServerImageMaker/opsString';
import { formatEpisodeDuration } from 'utils/time/dates';
import { get } from 'lodash-es';
import { getTrackByTrackId } from 'state/Tracks/services';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import { Menu } from 'components/Tooltip';
import { PlaybackTypeValue, STATION_TYPE } from 'constants/stationTypes';
import { ShareTypes } from 'components/Social/Share/constants';
import { THUMB_RES } from 'components/MediaServerImage';
import type { PlaybackState } from 'components/Player/PlayerState/types';

type Props = {
  ampUrl: string;
  appMounted: boolean;
  artistId: number;
  artistName: string;
  callLetters: string;
  countryCode: string;
  currentlyPlaying: any;
  description: string;
  descriptionUrl?: string;
  duration: number;
  explicitLyrics?: boolean;
  fetchLyrics?: boolean;
  hasLyrics?: boolean;
  hideDescription: boolean;
  isCustomRadioEnabled: boolean;
  isLoggedOut?: boolean;
  isOnDemandTrack?: boolean;
  meta?: string;
  ODPermitted?: boolean;
  onAddToMyMusic: (a: { trackId: number; trackName: string }) => undefined;
  onAddToPlaylist: (a: { trackId: number; trackName: string }) => undefined;
  onLyrics: () => undefined;
  onShare: () => undefined;
  openSignup: () => void;
  overflowEntitlements: any;
  playable: boolean;
  playButtonNumber: boolean;
  playedFrom: number;
  playingState: PlaybackState;
  progress: number;
  secondsPlayed: number;
  seedId: number | string;
  seedType: PlaybackTypeValue;
  sentiment: number;
  stationId: string | number;
  stationSoftgate: any;
  stationType: PlaybackTypeValue;
  title: string;
  titleUrl: string;
  trackCount: number;
  trackId: number;
  trackNumber: string;
  trackThumb: string;
  translate: IGetTranslateFunctionResponse;
  updateThumbs?: (a: {
    existingSentiment: number;
    seedId: number | string;
    sentiment: number;
    stationId: string | number;
    stationType: string;
    trackId: number;
    trackingData: {
      callLetters: string;
      id: number;
      itemId: number;
      itemName: string;
      name: string;
      songId: number;
      songName: string;
      type: string;
    };
  }) => undefined;
};

type State = {
  hasLyrics: boolean;
};

export default class PlaylistTableRow extends Component<Props, State> {
  asyncData: CancelablePromise<State> | null = null;

  static defaultProps = {
    albumUpsell: true,
    explicitLyrics: false,
    fetchLyrics: false,
    isOnDemandTrack: true,
    overflowEntitlements: {},
    playable: true,
    playButtonNumber: false,
    title: '',
    titleUrl: '',
  };

  state = {
    hasLyrics: false,
  };

  componentDidMount(): void {
    this.asyncData = withCancel(this.getInitialStateAsync());
    this.asyncData.then(data => this.setState(data));
  }

  componentWillUnmount(): void {
    if (this.asyncData) this.asyncData.cancel();
  }

  getInitialStateAsync = (): Promise<State> => {
    const { fetchLyrics, hasLyrics, trackId, ampUrl } = this.props;

    // Get track lyrics if props.fetchLyrics is present
    if (!fetchLyrics || !trackId) {
      return Promise.resolve({
        hasLyrics: hasLyrics || false,
      });
    }

    return transport(getTrackByTrackId({ ampUrl, trackId }))
      .then(({ data }) => data.track)
      .then(({ lyricsId }) => ({
        hasLyrics: Number(lyricsId) > 0,
      }));
  };

  onLyrics = () => {
    const { onLyrics } = this.props;
    if (onLyrics) onLyrics();
    return true;
  };

  onShare = () => {
    const { onShare } = this.props;
    if (onShare) onShare();
    return true;
  };

  loggedInAction = (fn: () => boolean) => {
    const { isLoggedOut, openSignup, seedType, stationSoftgate } = this.props;
    const hasSoftgate = get(stationSoftgate, seedType);
    if (hasSoftgate && isLoggedOut) {
      openSignup();
    } else {
      fn();
    }
  };

  updateThumb = (sentiment: number) => {
    const {
      artistId,
      artistName,
      callLetters,
      sentiment: currentSentiment,
      trackId,
      seedId,
      stationId,
      seedType,
      title,
    } = this.props;
    const newSentiment = sentiment === currentSentiment ? 0 : sentiment;

    if (this.props.updateThumbs) {
      this.props.updateThumbs({
        existingSentiment: currentSentiment || 0,
        seedId,
        sentiment: newSentiment,
        stationId,
        stationType: seedType,
        trackId,
        trackingData: {
          callLetters,
          id: artistId,
          itemId: artistId,
          itemName: artistName,
          name: artistName,
          songId: trackId,
          songName: title,
          type: seedType,
        },
      });
    }

    return true;
  };

  onThumbUpClick = () => this.updateThumb(1);

  onThumbDownClick = () => this.updateThumb(-1);

  onAddToMyMusic = () => {
    const { trackId, title: trackName } = this.props;
    if (typeof this.props.onAddToMyMusic === 'function') {
      this.props.onAddToMyMusic({ trackId, trackName });
    }
    return true;
  };

  onAddToPlaylist = () => {
    const { trackId, title: trackName } = this.props;
    if (typeof this.props.onAddToPlaylist === 'function') {
      this.props.onAddToPlaylist({ trackId, trackName });
    }
    return true;
  };

  renderThumbs = (sentiment: number, appMounted: boolean) => {
    const isThumbedUp = appMounted && sentiment === 1;
    const isThumbedDown = appMounted && sentiment === -1;

    return (
      <ThumbsContainer>
        <ThumbButton
          aria-label="Thumb Down"
          onClick={() => this.loggedInAction(this.onThumbDownClick)}
        >
          <ThumbsDown isFilled={isThumbedDown} />
        </ThumbButton>
        <ThumbButton
          aria-label="Thumb Up"
          onClick={() => this.loggedInAction(this.onThumbUpClick)}
        >
          <ThumbsUp isFilled={isThumbedUp} />
        </ThumbButton>
      </ThumbsContainer>
    );
  };

  render() {
    const {
      artistId,
      artistName,
      countryCode,
      currentlyPlaying,
      description,
      descriptionUrl,
      duration,
      explicitLyrics,
      hideDescription,
      isCustomRadioEnabled,
      isOnDemandTrack,
      meta,
      ODPermitted,
      playable,
      playButtonNumber,
      playedFrom,
      playingState,
      progress,
      secondsPlayed,
      seedId,
      seedType,
      stationId,
      stationType,
      title,
      titleUrl,
      trackCount,
      trackId,
      trackNumber,
      trackThumb,
      translate,
    } = this.props;

    const isOnDemand = isOnDemandTrack || !ODPermitted;
    const enableAddToPlaylist =
      countryCode === countryCodes.US || countryCode === countryCodes.CA;
    const explicitIcon = explicitLyrics ? (
      <ExplicitLyrics floatRight key="explicit-lyrics-icon" />
    ) : null;

    const isAlbum = seedType === STATION_TYPE.ALBUM;
    const isArtist = seedType === STATION_TYPE.ARTIST;
    const isAlbumStation = stationType === STATION_TYPE.ALBUM;

    const isTrack =
      (seedType === STATION_TYPE.TRACK && !isAlbumStation) ||
      (!!isArtist && !!trackId);
    const playButtonArtistId = artistId || stationId || seedId;

    let playButtonStationId = stationId;
    let playButtonStationType: PlaybackTypeValue;
    if (isTrack) {
      playButtonStationType = STATION_TYPE.TRACK as PlaybackTypeValue;
      playButtonStationId = seedId;
    } else if (isAlbum || isAlbumStation) {
      playButtonStationType = STATION_TYPE.ALBUM as PlaybackTypeValue;
    } else {
      playButtonStationType = STATION_TYPE.ARTIST as PlaybackTypeValue;
    }

    const includeMask = !playButtonNumber ? <Mask /> : null;
    const topTracksNumberCell = trackCount ? (
      <TrackNumberCell key="track-number">{trackCount}</TrackNumberCell>
    ) : null;

    const playButtonNumberCell = playButtonNumber ? (
      <PlayButtonNumberCell isOnDemand={isOnDemand}>
        {trackNumber}
      </PlayButtonNumberCell>
    ) : null;

    const includeThumbImage = (
      <CatalogImage
        alt="Thumbnail Image"
        aspectRatio={1}
        id={trackId}
        ops={[fit(THUMB_RES, THUMB_RES)]}
        src={trackThumb}
        type={STATION_TYPE.TRACK}
      />
    );

    const descriptionCell = descriptionUrl ? (
      <ArtistCell>
        <NavLink
          css={theme => ({ color: theme.colors.gray['400'] })}
          to={descriptionUrl}
        >
          {description}
        </NavLink>
      </ArtistCell>
    ) : null;

    const moreMenu = [];

    const { appMounted, sentiment } = this.props;

    let artCell;
    let trackCell;

    if (!isOnDemand) {
      artCell = (
        <ArtCellDefault isOnDemand={isOnDemand}>
          <NavLink classes={['relative', 'ui-on-dark']} title={title}>
            <span>{trackCount}</span>
          </NavLink>
        </ArtCellDefault>
      );

      trackCell = (
        <TrackCellDefault>
          <DisabledTitle key="track-title-text-disabled">{title}</DisabledTitle>
          {explicitIcon}
        </TrackCellDefault>
      );
    } else if (seedType === STATION_TYPE.PODCAST) {
      artCell = (
        <ArtCellPodcast>
          {playable ? (
            <div style={{ position: 'relative' }}>
              <PlayButton
                currentlyPlaying={currentlyPlaying}
                deferPlay={false}
                key={trackId}
                playedFrom={playedFrom}
                playingState={playingState}
                seedId={seedId}
                stationId={seedId}
                stationType={seedType}
                trackId={trackId}
              />
              <PodcastCircleProgressContainer>
                <Circle
                  data-test="podcast-play-progress"
                  percent={progress * 100}
                  strokeColor={colors.red.primary}
                  strokeWidth={progress ? 6 : 0}
                  trailColor="transparent"
                />
              </PodcastCircleProgressContainer>
            </div>
          ) : null}
        </ArtCellPodcast>
      );

      trackCell = (
        <TrackCellPodcast>
          <Ellipsis>
            <NavLink to={titleUrl}>{title}</NavLink>
          </Ellipsis>
          <PodcastMetaData>
            <span>{meta}</span>
            {duration ? (
              <DurationPodcast data-test="playlist-row-podcast-duration">
                {formatEpisodeDuration(duration)}
              </DurationPodcast>
            ) : null}
          </PodcastMetaData>
        </TrackCellPodcast>
      );
    } else {
      artCell = (
        <ArtCellDefault
          isOnDemand={isOnDemand}
          playButtonNumber={playButtonNumber}
        >
          <NavLink as="span" title={title} to={playable ? undefined : titleUrl}>
            {includeThumbImage}
            <Hover playButtonNumber={playButtonNumber}>
              {includeMask}
              {playable && isCustomRadioEnabled ? (
                <PlayButtonWrapper>
                  <PlayButton
                    artistId={playButtonArtistId}
                    artistName={artistName}
                    currentlyPlaying={currentlyPlaying}
                    data-test="play-button"
                    key="play-button"
                    playedFrom={playedFrom}
                    playingState={playingState}
                    stationId={playButtonStationId}
                    stationType={playButtonStationType}
                    trackId={trackId}
                    trackName={title}
                  />
                </PlayButtonWrapper>
              ) : null}
            </Hover>
          </NavLink>
        </ArtCellDefault>
      );

      trackCell = (
        <TrackCellDefault>
          <NavLink to={titleUrl}>{title}</NavLink>
          {explicitIcon}
        </TrackCellDefault>
      );
    }

    if (isOnDemandTrack && enableAddToPlaylist) {
      moreMenu.push(
        <NavLink
          key="addToPlaylist"
          onClick={this.onAddToPlaylist}
          title={translate('Add to Playlist')}
        >
          {translate('Add to Playlist')}
        </NavLink>,
      );
      moreMenu.push(<Separator key="sep" />);
    }

    if (seedType === STATION_TYPE.PODCAST) {
      moreMenu.push(
        ...[
          <ShareButton
            episodeId={trackId}
            hideDescription={hideDescription}
            key="share"
            onAfterClick={this.onShare}
            seedId={seedId}
            seedType={STATION_TYPE.PODCAST}
            stationName={title}
            type={ShareTypes.Link}
            url={titleUrl}
          />,
          <NavLink
            key="episode_info"
            title={translate('Episode Info')}
            to={titleUrl}
          >
            {translate('Episode Info')}
          </NavLink>,
          <MarkAsPlayedToggle
            duration={duration}
            key="mark-as-played-toggle"
            secondsPlayed={secondsPlayed}
            seedId={seedId}
            trackId={trackId}
          />,
        ],
      );
    } else {
      moreMenu.push(
        <ShareButton
          episodeId={trackId}
          key="share"
          onAfterClick={this.onShare}
          seedId={seedId}
          seedType={STATION_TYPE.TRACK}
          stationName={`${description} - ${title}`}
          type={ShareTypes.Link}
          url={titleUrl}
        />,
      );
    }

    if (this.state.hasLyrics) {
      moreMenu.push(
        <NavLink
          key="lyrics"
          onClick={this.onLyrics}
          title={translate('Lyrics')}
          to={titleUrl}
        >
          {translate('Lyrics')}
        </NavLink>,
      );
    }

    const mappedMoreMenu = moreMenu.map(menuItem => (
      <Menu.Item key={menuItem.key}>{menuItem}</Menu.Item>
    ));

    const hideThumbs =
      (
        [STATION_TYPE.TALK_EPISODE, STATION_TYPE.PODCAST] as Array<string>
      ).includes(seedType) || !isCustomRadioEnabled;

    const inside = (
      <>
        {playButtonNumberCell}
        {topTracksNumberCell}
        {artCell}
        {trackCell}
        {descriptionCell}
        <ActionsCell>
          <ShouldShow shouldShow={!hideThumbs}>
            {this.renderThumbs(sentiment, appMounted)}
          </ShouldShow>
          <ShouldShow shouldShow={seedType === STATION_TYPE.PODCAST}>
            <DurationPodcastMobile>
              {formatEpisodeDuration(duration)}
            </DurationPodcastMobile>
          </ShouldShow>
          <RowDropdown showThumbs={!hideThumbs}>
            <Menu.List>{mappedMoreMenu}</Menu.List>
          </RowDropdown>
        </ActionsCell>
      </>
    );

    return (
      <Row
        data-test={`playlist-row-${seedType || stationType}`}
        playable={playable}
        playButtonNumber={playButtonNumber}
      >
        {inside}
      </Row>
    );
  }
}
