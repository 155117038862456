import AlbumArtWrapper from './primitives/AlbumArtWrapper';
import AlbumInfo from './primitives/AlbumInfo';
import AlbumMeta from './primitives/AlbumMeta';
import AlbumReleaseInfo from './primitives/AlbumReleaseInfo';
import Bullet from './primitives/Bullet';
import ExplicitLyrics from 'components/ExplicitLyrics';
import ExplicitLyricsWrapper from './primitives/ExplicitLyricsWrapper';
import LatestReleaseOverflow from './LatestReleaseOverflow';
import MediaServerImage, { TILE_FIT } from 'components/MediaServerImage';
import NavLink from 'components/NavLink';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PlayButtonWrapper from './primitives/PlayButtonWrapper';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import Section from 'components/Section';
import ShouldShow from 'components/ShouldShow';
import TotalSongsWrapper from './primitives/TotalSongsWrapper';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { fit } from 'utils/mediaServerImageMaker/opsString';
import {
  getCurrentArtistId,
  getCurrentArtistLatestRelease,
} from 'state/Artists/selectors';
import { getCurrentPath } from 'state/Routing/selectors';
import { getCustomRadioEnabled } from 'state/Features/selectors';
import { getMonth } from 'utils/time/dates';
import { playAlbumSelector } from 'state/Entitlements/selectors';
import { slugify } from 'utils/string';
import { useSelector } from 'react-redux';
import type { FunctionComponent } from 'react';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

const LatestRelease: FunctionComponent = () => {
  const artistId = useSelector(getCurrentArtistId);
  const customRadioEnabled = useSelector(getCustomRadioEnabled);
  const latestRelease = useSelector(getCurrentArtistLatestRelease);
  const url = useSelector(getCurrentPath);
  const albumPlayback = useSelector(playAlbumSelector);
  const translate = useTranslate();

  if (!latestRelease?.albumId) return null;

  const {
    title = '',
    albumId,
    explicitLyrics,
    totalSongs,
    image,
    releaseDate,
  } = latestRelease;
  const albumUrl = `${url}albums/${slugify(title)}-${albumId}/`;
  const numberOfReleaseSongs = translate(['1 Song', '{n} Songs', 'n'], {
    n: Number(totalSongs),
  });
  const dateObj = releaseDate ? new Date(releaseDate) : null;
  const formattedReleaseDate = dateObj
    ? `${getMonth(dateObj.getMonth() + 1, translate)} ${dateObj.getFullYear()}`
    : null;

  return (
    <Section
      as="h2"
      dataTest="section-latest-release"
      fullWidth
      header={translate('Latest Release')}
    >
      <NavLink to={albumUrl}>
        <AlbumArtWrapper>
          <MediaServerImage
            alt={`${title} album art`}
            aspectRatio={1}
            ops={[fit(...TILE_FIT)]}
            src={image}
          />
          {albumPlayback && customRadioEnabled ? (
            <PlayButtonWrapper>
              <PlayButton
                artistId={artistId}
                className="play"
                deferPlay={!!albumUrl}
                playedFrom={PLAYED_FROM.PROF_ARTIST_MAIN_LATEST_RELEASE}
                seedId={albumId}
                stationId={albumId}
                stationType="album"
              />
            </PlayButtonWrapper>
          ) : null}
        </AlbumArtWrapper>
      </NavLink>
      <AlbumInfo>
        <AlbumMeta>
          <li>
            <NavLink to={albumUrl}>{title}</NavLink>
          </li>
          <AlbumReleaseInfo>{`${formattedReleaseDate}`}</AlbumReleaseInfo>
          <TotalSongsWrapper>
            <Bullet>&bull;</Bullet> {numberOfReleaseSongs}
          </TotalSongsWrapper>
        </AlbumMeta>

        <ShouldShow shouldShow={explicitLyrics}>
          <ExplicitLyricsWrapper>
            <ExplicitLyrics />
          </ExplicitLyricsWrapper>
        </ShouldShow>

        <LatestReleaseOverflow />
      </AlbumInfo>
    </Section>
  );
};

export default LatestRelease;
