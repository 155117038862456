import styled from '@emotion/styled';

type Props = {
  isOnDemand?: boolean;
  playButtonNumber?: boolean;
};

const ArtCellDefault = styled('td')<Props>(
  ({ isOnDemand, playButtonNumber, theme }) => ({
    ...(isOnDemand
      ? {}
      : {
          'a > span': {
            color: theme.colors.gray.medium,
          },
        }),
    ...(playButtonNumber
      ? {
          color: theme.colors.gray.medium,
          fontSize: theme.fonts.size.xsmall,
          minHeight: '4rem',
          paddingLeft: '0.5rem',
          paddingTop: '3.4rem',
          position: 'relative',
          a: {
            color: theme.colors.gray.medium,
            display: 'block',
          },
          button: {
            display: 'none',
            height: '3rem',
            left: '-3rem',
            position: 'absolute',
            top: '0.8rem',
            width: '3rem',
          },
        }
      : {
          display: 'inline-block',
          flex: '0 1 auto',
          position: 'relative',
          width: '4.5rem',
        }),
  }),
);

export default ArtCellDefault;
