import DescriptionWrapper from 'primitives/Hero/DescriptionWrapper';
import Featuring from 'shared/ui/components/Featuring';
import FilledButton from 'primitives/Buttons/FilledButton';
import Follow from 'styles/icons/Follow';
import FollowButtonWrapper from 'primitives/Hero/FollowButtonWrapper';
import HeroBackground from 'components/Hero/HeroBackground';
import HeroContent from 'primitives/Hero/HeroContent';
import HeroMidSection from 'primitives/Hero/HeroMidSection';
import HeroOverlay from 'components/Hero/HeroOverlay/HeroOverlay';
import HeroPlayButton from 'components/Player/HeroPlayButton';
import HeroPlaySection from 'primitives/Hero/HeroPlaySection';
import HeroRoot from 'primitives/Hero/HeroRoot';
import HeroTitle from 'primitives/Hero/HeroTitle';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import ShouldShow from 'components/ShouldShow';
import theme from 'styles/themes/default';
import ThumbnailWrapper from 'primitives/Hero/ThumbnailWrapper';
import Title from 'components/Hero/HeroTitle/HeroTitle';
import trackers from 'trackers';
import { Events } from 'modules/Analytics';
import { parse } from 'qs';
import {
  PrimaryBackground,
  TabletBackground,
  Thumbnail,
} from 'components/Hero/helpers';
import { PureComponent } from 'react';
import { STATION_TYPE } from 'constants/stationTypes';
import type { IGetTranslateFunctionResponse } from 'redux-i18n';
import type { showFavoriteChangedGrowl as showFavoriteChangedGrowlAction } from 'state/UI/actions';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

type Props = {
  artistName: string;
  backgroundColor: string;
  customRadioEnabled: boolean;
  isFavorite: boolean;
  listenInAppVisible: boolean;
  mediaServerUrl: string;
  primaryBackgroundSrc: string;
  resetHero: () => void;
  seedId: number | string;
  setHasHero: (hasHero: boolean | null | undefined) => void;
  setHeroPremiumBackground: (
    heroImg: string,
    backgroundColor: string,
    noLogo: boolean,
  ) => void;
  showFavoriteChangedGrowl: typeof showFavoriteChangedGrowlAction;
  siteUrl: string;
  subscriptionType: string;
  toggleFavoriteArtist: (artist: { artistId: number }) => void;
  translate: IGetTranslateFunctionResponse;
};

type State = {
  favoriteToggled: boolean;
};

class ArtistHero extends PureComponent<Props, State> {
  state = {
    favoriteToggled: false,
  };

  componentDidMount() {
    this.props.setHasHero(true);
    const query = parse(window.location.search, { ignoreQueryPrefix: true });
    if (this.state.favoriteToggled || query.follow !== 'true') return;
    this.toggleFavorite();
  }

  componentDidUpdate(prevProps: Props) {
    const { artistName, isFavorite, showFavoriteChangedGrowl } = this.props;

    if (prevProps.isFavorite !== isFavorite && this.state.favoriteToggled) {
      showFavoriteChangedGrowl({ isFavorite, name: artistName });
      this.setState({
        favoriteToggled: false,
      });
    }
  }

  componentWillUnmount() {
    this.props.resetHero();
  }

  toggleFavorite = () => {
    const { artistName, seedId, isFavorite } = this.props;

    // Braze Tracking event both registered and anonymous user
    trackers.track(isFavorite ? Events.UnfollowArtist : Events.FollowArtist, {
      artist_name: artistName,
      artistId: seedId,
    });

    this.props.toggleFavoriteArtist({ artistId: Number(seedId) });
    this.setState({
      favoriteToggled: true,
    });
  };

  render() {
    const {
      backgroundColor = theme.colors.black.primary,
      customRadioEnabled,
      mediaServerUrl,
      isFavorite,
      primaryBackgroundSrc,
      seedId,
      siteUrl,
      subscriptionType,
      translate,
      artistName,
    } = this.props;
    // favoriting is based on custom radio feature flag
    const canFavorite = customRadioEnabled;

    return (
      <HeroRoot data-test="hero-container">
        <HeroBackground
          backgroundColor={backgroundColor}
          noMask
          primaryBackground={
            primaryBackgroundSrc ? (
              <PrimaryBackground
                mediaServerUrl={mediaServerUrl}
                seedType={STATION_TYPE.ARTIST}
                siteUrl={siteUrl}
                src={primaryBackgroundSrc}
                title={artistName}
              />
            ) : null
          }
          tabletBackground={
            <TabletBackground
              customBlur={40}
              height={1440}
              mediaServerUrl={mediaServerUrl}
              seedId={seedId as string}
              seedType={STATION_TYPE.ARTIST}
              title={artistName}
              width={1440}
            />
          }
        />

        <HeroOverlay>
          <HeroMidSection data-test="hero-artist">
            <ThumbnailWrapper isSmall={!!primaryBackgroundSrc}>
              <Thumbnail
                mediaServerUrl={mediaServerUrl}
                seedId={seedId as string}
                seedType={STATION_TYPE.ARTIST}
                title={artistName}
              />
            </ThumbnailWrapper>

            <HeroContent>
              <HeroTitle data-test="artist-title">
                <Title clamp text={artistName} />
              </HeroTitle>
              <DescriptionWrapper data-test="artist-featured">
                <Featuring
                  artistId={seedId}
                  key={`featuring${seedId}`}
                  truncate={60}
                />
              </DescriptionWrapper>
              <HeroPlaySection>
                <ShouldShow shouldShow={!!seedId && customRadioEnabled}>
                  <PlayButton
                    ButtonComponent={HeroPlayButton}
                    playedFrom={PLAYED_FROM.PROF_ARTIST_PLAY}
                    seedId={seedId}
                    stationId={seedId}
                    stationType={STATION_TYPE.ARTIST}
                    subscriptionType={subscriptionType}
                  />
                </ShouldShow>
                <ShouldShow shouldShow={canFavorite}>
                  <FollowButtonWrapper>
                    {isFavorite ? (
                      <FilledButton
                        data-test="follow-button"
                        onClick={this.toggleFavorite}
                        size="small"
                        styleType="light"
                      >
                        {translate('Following')}
                      </FilledButton>
                    ) : (
                      <OutlinedButton
                        data-test="follow-button"
                        onClick={this.toggleFavorite}
                        size="small"
                        styleType="light"
                      >
                        <Follow />
                        {translate('Follow')}
                      </OutlinedButton>
                    )}
                  </FollowButtonWrapper>
                </ShouldShow>
              </HeroPlaySection>
            </HeroContent>
          </HeroMidSection>
        </HeroOverlay>
      </HeroRoot>
    );
  }
}

export default ArtistHero;
